<template>
  <base-section id="careers">
  	<base-section-heading
      color="grey lighten-2"
      title="Tentukan tujuan karir anda berikutnya"
    />
    <div 
    data-aos="fade-right"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
    data-aos-anchor-placement="top-center">
    <v-container>
      <template>
        <v-row>
        <v-col
          v-for="(data, i) in careergroup"
          :key="i"
          cols="12"
          xs="12"
          md="4"
          sm="6"
          class="pa-1"
          @click="Group(data.web_career_category_id, data.web_career_category_count)"
        >
        <v-card
          class="mx-auto cursor"
          max-width="400"
          height="250px"
        >
          <v-img
            class="white--text align-end"
            height="250px"
            :src="require(`@/assets/career-${i}.jpg`)"
            gradient="to right, rgba(0, 31, 72, 0.7), rgba(0, 0, 0, 0)"
            :alt="data.web_career_category_name"
          >
            <v-card-title style="margin-right:140px" v-html="`${SplitJoin(data.web_career_category_name)} (${data.web_career_category_count} posisi)`"></v-card-title>
          </v-img>
          <v-card-text>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>
      </template>
    </v-container>
    </div>
    <div class="grey lighten-4">
    <v-container>
        <v-row justify="center">
          <v-col cols="12">
		    <base-section-heading
		      color="grey lighten-2"
			      title="Jadilah bagian dari sejarah industri syariah"
			    />
			    <v-container class="text-justify">
			    	<!-- #######  YAY, I AM THE SOURCE EDITOR! #########-->
            <p><span style="font-weight: 400;">Kami menyediakan sejumlah program unggulan dan sistem terpadu dalam pengembangan karir karyawan yang tidak ditemukan di tempat lain, diantaranya :</span></p>
            <p>&nbsp;</p>
            <p><strong>Kami adalah Perusahaan Asuransi Syariah Pertama yang Berkembang Pesat</strong></p>
            <p><span style="font-weight: 400;">Kami adalah pelaku bisnis asuransi syariah yang pertama hadir di Indonesia yang telah memiliki nama dan brand yang terkenal dan menjadi pilihan masyarakat. Saat ini jangkauan wilayah pemasaran kami hampir mencakup seluruh wilayah Indonesia sehingga memudahkan bagi para peserta asuransi untuk berhubungan dengan kami.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Sistem Kerja yang Terintegrasi</strong></p>
            <p><span style="font-weight: 400;">Kantor pusat kami memiliki jaringan terintegrasi yang dapat menghubungkan seluruh departemen/divisi baik di Jakarta maupun di daerah, di mana kami telah melengkapi diri dengan fasilitas absensi dan komunikasi secara online, seperti TAP (Takaful Portal), SSO (Single Sign On), dan lain-lain. Melalui kecanggihan fasilitas ini, karyawan pada dasarnya dapat bekerja di mana saja (Work from Everywhere). Sedangkan kantor pusat kami yang berada di bilangan Jakarta Selatan memiliki akses strategis ke berbagai jalan utama dan fasilitas transportasi public sehingga mudah dijangkau dari mana saja.</span></p>
            <p>&nbsp;</p>
            <p><strong>Kami Menawarkan Jenjang Karir yang Menantang</strong></p>
            <p><span style="font-weight: 400;">Kami telah mengimplementasikan sistem manajemen sumber daya manusia berbasis kompetensi yang digunakan untuk menilai kinerja dan prestasi karyawan, serta menempatkan mereka pada posisi yang tepat. Kami menawarkan jenjang karir yang terprogram dan kesempatan yang luas bagi karyawan yang berprestasi untuk meraih kesuksesan.</span></p>
            <p>&nbsp;</p>
            <p><strong>Kami Membekali Karyawan dengan Berbagai Pengetahuan dan Keterampilan</strong></p>
            <p><span style="font-weight: 400;">Kami terus berusaha untuk meningkatkan kualitas kerja karyawan karena kami sadar bahwa mereka merupakan aset paling berharga bagi perusahaan. Berbagai program pelatihan yang kami sediakan diharapkan dapat membekali karyawan dengan keterampilan teknis maupun non teknis. Guna menumbuhkan profesionalisme kerja, kami juga mendorong karyawan untuk dapat meningkatkan pengetahuan dan keterampilan melalui modul pembelajaran secara mandiri.</span></p>
            <p>&nbsp;</p>
            <p><strong>Lingkungan Kerja yang Nyaman</strong></p>
            <p><span style="font-weight: 400;">Guna mendukung terciptanya kenyamanan bekerja, kami menyediakan ruang kerja yang tidak dibatasi secara kaku dan permanen karena semua ruang dapat dipakai oleh karyawan untuk bekerja. Disediakan pula fasilitas ruang bersantai (lounge) dengan suasana yang tenang dan nyaman, yang dapat digunakan karyawan untuk sarana bersosialisasi antar sesama karyawan. Terdapat juga ruangan khusus untuk melakukan aktivitas ibadah sehingga karyawan tidak perlu khawatir dalam menunaikan kewajiban agamanya.</span></p>
            <p><span style="font-weight: 400;">Sebagai wujud kepedulian kepada karyawan, kami secara rutin menyelenggarakan sharing session mengenai berbagai topik yang perlu didiskusikan.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Fasilitas Kesehatan dan Tunjangan Lainnya</strong></p>
            <p><span style="font-weight: 400;">Sebagai perusahaan yang bergerak di bidang perlindungan asuransi, kami tidak lupa menyediakan berbagai fasilitas Kesehatan bagi karyawan baik berupa asuransi untuk karyawan baik yang bersifat wajib maupun tambahan, selain tunjangan lain yang berguna untuk menjaga Kesehatan masing-masing karyawan agar dapat bekerja dengan optimal.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Menjunjung Nilai-Nilai Etika Kerja yang Tinggi</strong></p>
            <p><span style="font-weight: 400;">Nilai-nilai dan norma-norma yang berlaku di perusahaan menjadi pedoman standar perilaku bagi seluruh karyawan dalam menjalankan hubungan kerja dengan sesama rekan kerja, customer, maupun pihak-pihak lain. Nilai-nilai ini menjadi budaya perusahaan dalam rangka mendukung perwujudan visi dan misi perusahaan.</span></p>
    <v-list v-for="data in cards" :key="data.title">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>{{data.title}}</v-list-item-title>
            <v-list-item-subtitle>{{data.description}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
    </v-list>
			    </v-container>
			</v-col>
		</v-row>
	</v-container>
	</div>
	<div class="hidden-xs-only">
	  <v-container>
	        <v-row justify="center">
	          <v-col cols="12">
				  <v-carousel
              hide-delimiter-background
              delimiter-icon="mdi-minus"
              :continuous="true"
              >
				    <v-carousel-item
				      v-for="(item,i) in items"
				      :key="i"
				      :src="require('@/assets/'+item.src)"
				      transition="fade-transition"
              contain
              :alt="items.src"
				    ></v-carousel-item>
				  </v-carousel>
				</v-col>
				<v-col
	          v-for="card in cards"
	          :key="card.title"
	          cols="12"
	          md="3"
	        >
	          <base-info-card v-bind="card" />
	        </v-col>
			</v-row>
		</v-container>
	</div>
  	<v-container>
        <v-row justify="center">
			  <v-col
	          cols="12"
	          md="12"
	        >
	          <v-img
		        :src="require('@/assets/banner-homealt.jpeg')"
		        max-width="100%"
		        max-height="250"
		        tile
		      />
	        </v-col>
		</v-row>
	</v-container>
  <div class="grey lighten-4">
	<v-container>
		<base-section-heading
	      color="grey lighten-2"
	      title="Keuntungan Bergabung dengan Kami"
	    />
        <v-row>
        <v-col
          v-for="(data, i) in benefit"
          :key="i"
          cols="12"
          md="4"
        >
          <div 
          data-aos="zoom-in"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-center">
          <base-avatar-card
            v-bind="data"
            align="left"
            horizontal
          >
          </base-avatar-card>
          </div>
        </v-col>
      </v-row>
	</v-container>
  </div>
	<v-container>
	  <base-section-heading
	      title="Berikut daftar tujuan karir untuk anda berikutnya"
	    />
	  <v-row>
	  	<v-col cols="12" sm="12">
	        <v-select
            v-model="select"
	          :items="province"
            item-text="web_province_desc"
            item-value="web_province_id"
	          label="Provinsi"
	          dense
            persistent-hint
            return-object
            single-line
            @change="Career"
	        >
           <template slot="selection" slot-scope="data">
             {{ data.item.web_province_desc }} ({{ data.item.web_province_career_count }})
           </template>
           <template slot="item" slot-scope="data">
             {{ data.item.web_province_desc }} ({{ data.item.web_province_career_count }})
           </template>
          </v-select>
	    </v-col>
		  <v-col
	      cols="12"
	      md="12"
	    >
		  <v-card
		    class="mt-n5 mx-auto"
		    flat
		  >
		    <v-list-item two-line 
            v-for="data in career"
            :key="data.web_career_id"
            @click="Detail(data.web_career_id)">
		      <v-list-item-content>
		        <v-list-item-title>{{ data.web_career_name }}</v-list-item-title>
		        <v-list-item-subtitle><span class="font-weight-black fourth--text">{{ data.web_career_category_desc }}</span> &bull; {{ data.web_career_department_name }} &bull; Expired: {{ FormatDateIndo(data.web_career_edate) }}</v-list-item-subtitle>
		      </v-list-item-content>
		    </v-list-item>
		  </v-card>
		 </v-col>
		</v-row>
	</v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCareers',

    data: () => ({
      select: { web_province_id: '31', web_province_desc: 'DKI JAKARTA' },
      province: [],
      career: [],
      careergroup: [
          {web_career_category_id : 'insan', web_career_category_name: 'Takaful Karyawan', web_career_category_count: 0},
          {web_career_category_id : 'agen', web_career_category_name: 'Takaful Marketing Executive', web_career_category_count: 0},
          {web_career_category_id : 'magang', web_career_category_name: 'Takaful Magang', web_career_category_count: 0},
      ],
      cards: [
        {
          title: '01 Maslahah',
          description: 'Perusahaan berupaya memelihara tujuan syara\' dan meraih manfaat dan atau menghindarkan kemudharatan.'
        },
        {
          title: '02 AmaNah',
          description: 'Senantiasa berupaya menjaga kepercayaan dan mengelolanya dengan penuh tanggung jawab, kesungguhan dan kecakapan dalam melaksanakan seluruh aktivitas demi terpenuhinya maslahah seluruh stakeholder'
        },
        {
          title: '03 TA`awun',
          description: 'Kemampuan perusahaan dalam mensinergikan potensi kebaikan seluruh stakeholder untuk saling tolong menolong dalam kebaikan. Hanya dengan komitmen saling menolong, saling mendukung, saling percaya, saling peduli dan saling berbagi gagasan, kita dapat mencapai dan mempertahankan maslahah bersama.'
        },
        {
          title: '04 Progressif dan Profesional',
          description: 'Kemampuan perusahaan dalam melakukan perubahan yang berkemajuan, meningkat, meluas, berkelanjutan baik secara kuantitatif ataupun kualitatif dengan kecakapan dan keahlian dalam memberikan pelayanan prima untuk seluruh stakeholder.'
        },
      ],
      items: [
          {
            src: 'employee-1.jpg',
          },
          {
            src: 'employee-2.jpg',
          },
          {
            src: 'employee-3.jpg',
          },
      ],
      benefit: [
        {
          title: 'Employees Health is Priority',
          icon: 'mdi-heart',
        },
        {
          title: 'Career growth opportunities',
          icon: 'mdi-arrow-up-thick',
        },
        {
          title: 'Religious & Positive Working Environment',
          icon: 'mdi-food-halal',
        },
        {
          title: 'Being part of a Fast Growing Team',
          icon: 'mdi-clock-fast',
        },
        {
          title: 'Competitive Allowance',
          icon: 'mdi-beach',
        },
        {
          title: 'Flexible working place (Work From Everywhere)',
          icon: 'mdi-map-marker',
        },
      ],
    }),
    created () {
      this.Province()
    },
    methods: {
      Province () {
        this.loading  = true
        let formdata = {
              web_province_actived: 'Y',
              order_by: 'provinces_id',
              order_type: 'ASC',
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebProvinceList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.province = response.data
            this.CareerGroup()
            this.Career()
            this.loading  = false
        })
        .catch(e => {
            alert(e)
            this.loading  = false
        })
      },
      Career () {
        this.loading  = true
        let id = this.select.web_province_id
        let formdata = {
              web_career_province: id,
              web_career_actived: 'true',
              order_by: 'web_career_id',
              order_type: 'ASC',
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebCareerList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.career = response.data
            this.loading  = false
        })
        .catch(e => {
            alert(e)
            this.loading  = false
        })
      },
      CareerGroup () {
        this.loading  = true
        let formdata = {
              web_career_category_id: '',
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebCareerCount'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let feedback = response.data
            for (let i = 0; i < feedback.length; i++){
              if (feedback[i].web_career_category_id === 'insan') {
                this.careergroup[0].web_career_category_count = feedback[i].web_career_category_count
              } else if (feedback[i].web_career_category_id === 'agen') {
                this.careergroup[1].web_career_category_count = feedback[i].web_career_category_count
              } else if (feedback[i].web_career_category_id === 'magang') {
                this.careergroup[2].web_career_category_count = feedback[i].web_career_category_count
              }
            }
            this.loading  = false
        })
        .catch(e => {
            alert(e)
            this.loading  = false
        })
      },
      MaxLength (content, max) {
        let returns = content
        if (content.length >= max) {
          returns = content.substr(0,max) + '... '
        }
        return returns
      },
      FormatDateIndo (date) {
        return this.$functions.FormatDateIndo(date)
      },
      Group (url, count) {
        if (count > 0) {
          this.$router.push({name: 'karir-'+url, params: { url: url }})
        }
      },
      Detail (url) {
        this.$router.push({name: 'karir-detail', params: { url: url }})
      },
      SplitJoin (string) {
        return string.split(' ').join('<br>')
      }
    }
  }
</script>
